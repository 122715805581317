var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticClass: "icon-class" },
    [
      _c("span", { domProps: { textContent: _vm._s(_vm.data.txt) } }),
      _c("svg-icon", {
        staticClass: "icon",
        attrs: { name: _vm.data.name.toLowerCase(), width: "16", height: "16" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }